import axios from 'axios';
import handleLaravelError from './handleResponse/handleLaravelError';

export const handleSuccess = (response) => response.data;
export const handleSuccessRoot = (response) => response;

export const ServiceFactory = (serviceHandleSuccess = handleSuccessRoot, serviceHandleError = handleLaravelError) => {
  const AxiosService = axios?.create({
    baseURL: `${process.env.API_URL}/api/`,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
    withCredentials: true,
  });

  AxiosService?.interceptors?.response?.use(serviceHandleSuccess, serviceHandleError);

  return AxiosService;
};

const Service = ServiceFactory(handleSuccess, handleLaravelError);
export { handleLaravelError as handleError };

export default Service;
