import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/components/Errors/ErrorMessage.scss';

/**
 * A component used to display a full-screen error message.
 *
 * @usage;
 *
 *		import { ErrorMessage } from '../app'
 *
 *		...
 *		<ErrorMessage
 * 	 		code={401}
 * 	 		message="Sorry, you need to log in"
 * 	 	/>
 *		...
 *
 * @author Sam Sehnert <sam@customd.com>
 *
 * @type {Component}
 */
const ErrorMessage = ({ title, message, children, onRetry, retryLabel }) => (
  <div className="error">
    <div className="error__content">
      <h1 className="error__title">{title}</h1>
      {message && <h2 className="error__message">{message}</h2>}
      {children}
      {onRetry && (
        <button type="button" onClick={onRetry}>
          {retryLabel}
        </button>
      )}
    </div>
  </div>
);

ErrorMessage.propTypes = {
  /**
   * The main title of the error message.
   */
  title: PropTypes.string,

  /**
   * An optional sub-message to display.
   * Typically used to explain an error in more detail, e.g.,
   * next steps, what went wrong, how to resolve, or what to expect.
   */
  message: PropTypes.string,

  /**
   * Should be a node, or an array of nodes.
   * This is content which might help the user
   * resolve the error, or guide toward resolution
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),

  /**
   * This should be a function to run on retry click.
   */
  onRetry: PropTypes.func,

  /** The label for retry function. Only used in conjunction with onRetry */
  retryLabel: PropTypes.node,
};

/**
 * Set the default values of properties.
 * @type {Object}
 */
ErrorMessage.defaultProps = {
  title: 'Sorry, an error occurred',
  retryLabel: 'Retry',
  onRetry: null,
  children: null,
  message: '',
};

export default ErrorMessage;
