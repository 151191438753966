import ServiceError from './ServiceError';

const DEFAULT_MESSAGE = 'Request was cancelled';
class ServiceAbort extends ServiceError {
  constructor(error, message = DEFAULT_MESSAGE) {
    const defaultMessage = DEFAULT_MESSAGE;
    super(error, message, defaultMessage);
  }
}

export default ServiceAbort;
