const DEFAULT_MESSAGE = 'An unexpected error has occurred.';

class ServiceError extends Error {
  constructor(error, message = DEFAULT_MESSAGE, defaultMessage = DEFAULT_MESSAGE) {
    super(message);
    this.name = this.constructor.name;
    this.error = error;
    this.defaultMessage = defaultMessage;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  getError = () => this.error;
}

export default ServiceError;
