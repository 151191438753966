const INIT_STATE = {};

const userProfile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return action.payload;
    default:
      return state;
  }
};

export default userProfile;
