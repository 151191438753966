import React from 'react';
import Loadable from 'react-loadable';
import DefaultLayout, { DefaultLoading } from './DefaultLayout';
import AuthedLayout from './AuthedLayout';
import AdminLayout, { AdminLoading } from './AdminLayout';

const loadableLayout = (loader, delay = 300) => (Layout = DefaultLayout, loading = DefaultLoading) => {
  return Loadable({
    loader,
    delay,
    loading,
    render: (loaded, props) => <Layout component={loaded.default} {...props} />,
  });
};

export { DefaultLayout, DefaultLoading, AuthedLayout, loadableLayout, AdminLayout, AdminLoading };
