import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import * as fromState from '../reducers';

/**
 * This layout is set up to determine if the user is logged in or not, and if not, redirect them to the login page setting a return url
 * back to where they came from
 */
class AdminLayout extends React.Component {
  render() {
    const { component: Component, getIsLoggedIn, hasAccess, ...rest } = this.props;

    if (!getIsLoggedIn) {
      // return (
      //   <Redirect
      //     to={{
      //       pathname: `/${region}/auth/login`,
      //       state: { from: this.props.location },
      //     }}
      //   />
      // );
    }

    if (!hasAccess) {
      // return (
      //   <Redirect
      //     to={{
      //       pathname: `/admin`,
      //       state: { from: this.props.location },
      //     }}
      //   />
      // );
    }

    return (
      <Route
        {...rest}
        render={matchProps => (
          <div key="RouteDiv">
            <main className="site-content">
              <Component {...matchProps} />
            </main>
          </div>
        )}
      />
    );
  }
}

// Mapped props to get the loggin state from our store
const mapStateToProps = (state, ownProps) => {
  return {
    getIsLoggedIn: fromState.getIsLoggedIn(state),
    hasAccess: fromState.getSessionHasRoles(state, ['Developer']),
  };
};

const ConnectedDefaultLayout = connect(mapStateToProps, {})(AdminLayout);

const RoutedDefaultLayout = withRouter(ConnectedDefaultLayout);

export const AdminLoading = props => <RoutedDefaultLayout {...props} component={() => <div />} />;

export default connect(mapStateToProps)(AdminLayout);
