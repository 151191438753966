import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Messages } from 'primereact/messages';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Auth from '../../api/Auth';
import Loading from '../Shared/Loading';
import useRegion from '../../hooks/useRegion';
import useUserSecretQuestions from '../../hooks/useUserSecretQuestions';

import { ServiceValidation } from '../../api/errors';
import useHandleError from '../../hooks/useHandleError';

import './ResetPasswordForm.scss';
import './SecretQuestionFields.scss';
import TextInput from '../ClientForms/TextInput';
import { validatePasswordConfirmation } from '../ClientForms/helpers';
import useGoogleAnalyticsEventTracking from '../../hooks/useGoogleAnalyticsEventTracking';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Please enter a password'),
  password_confirmation: validatePasswordConfirmation,
  answer: Yup.string()
    .nullable()
    .test({
      name: 'RequiredWhenQuestionIsSet',
      message: 'The answer field is required',
      test: value => {
        if (value === null) {
          return true;
        }
        return value?.length;
      },
    }),
});

const ResetPasswordForm = () => {
  const messagesRef = useRef();
  const history = useHistory();
  const region = useRegion();
  const { search } = useLocation();
  const { params } = useRouteMatch();

  const [redirectTo, setRedirectTo] = useState(false);
  const [errorData, setErrorData] = useState({});

  useHandleError(errorData);

  const { sendCustomFormSubmitEvent } = useGoogleAnalyticsEventTracking();

  const username = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('email').replace(' ', '+');
  }, [search]);

  const [loading, secretQuestions] = useUserSecretQuestions(username);

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      messagesRef.current.clear();

      try {
        const data = {
          token: params.token,
          username,
          ...values,
        };
        if (data.answer === null) {
          delete data.answer;
        }
        const status = await Auth.reset(data);

        if (status.result === 'validation_error') {
          throw new ServiceValidation(status.errors);
        }

        await sendCustomFormSubmitEvent({
          email: username,
        });

        setRedirectTo(`/${region}/auth/login`);
        await new Promise(resolve => setTimeout(() => resolve(), 5000));
      } catch (e) {
        if (e instanceof ServiceValidation) {
          setErrorData({
            error: e,
            fieldExists: field => values[field],
            setError: (field, errorString) => setErrors({ [field]: errorString }),
            setAlert: messagesRef.current,
            overrideDefaultMessage: 'An error has occurred, please try again.',
          });
        } else if (Array.isArray(e.errors)) {
          e.errors.forEach(err => {
            messagesRef.current.show({
              severity: 'error',
              detail: ReactHtmlParser(err),
              sticky: true,
            });
          });
        } else {
          messagesRef.current.show({
            severity: 'error',
            detail: "Sorry, we couldn't reset your password at this time. Please try again later, or contact Art Money for assistance.",
            sticky: true,
          });
        }
      }
    },
    [username, params, region, messagesRef],
  );

  const handleRedirect = useCallback(() => history.push(redirectTo), [history, redirectTo]);

  const question = useMemo(() => (secretQuestions && secretQuestions[0] && secretQuestions[0].label) || '', [secretQuestions]);

  const initialValues = useMemo(
    () => ({
      password: '',
      password_confirmation: '',
      question: secretQuestions?.[0]?.value,
      answer: secretQuestions?.[0]?.value ? '' : null,
    }),
    [secretQuestions],
  );

  if (loading) {
    return <Loading size={42} />;
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting }) => (
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <div className="reset-password-form__password">
            <TextInput name="password" autoComplete="new-password" placeholder="Password" type="password" />
          </div>
          <div className="reset-password-form__password-conf">
            <TextInput name="password_confirmation" autoComplete="new-password" placeholder="Confirm password" type="password" />
          </div>
          {secretQuestions && (
            <div className="activate-form__secret-question">
              <h3 className="activate-form__secret-heading">Security Question</h3>
              <p className="reset-password-form__secret-body">To reset your password, please answer your security question.</p>

              <div className="secret-question-fields">
                <span className="label">{question}</span>
                <div className="secret-question-fields__secret-answer">
                  <TextInput name="answer" placeholder="Answer" />
                </div>
              </div>
            </div>
          )}
          <Messages ref={messagesRef} />

          <button type="submit" className="reset-password-form__submit" disabled={isSubmitting}>
            <span>Reset</span>
            <i className={`pi ${isSubmitting ? 'pi-spin pi-spinner' : 'pi-lock'}`} />
          </button>
          {!!redirectTo && (
            <div className="modal">
              <div>
                <div>Your new password has been set</div>
                <div className="modal-body">You can now use this new password to log in.</div>
                <div className="modal-footer">
                  <div className="modal-actions">
                    <button onClick={handleRedirect} type="button">
                      Log in
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
