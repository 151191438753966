import { useState, useCallback, useMemo } from 'react';

import getGalleryContactDetails from '../GalleryDashboard/contactDetails';

const useMenuItems = ({ region, isGalleryDashboard, isMobile, isLoggedIn }) => {
  const [activeMenus, setActiveMenus] = useState([]);

  const { email } = getGalleryContactDetails(region);

  const onToggle = useCallback(
    (label) => {
      const activeMenusCopy = [...activeMenus];
      if (isMobile) {
        if (activeMenusCopy.includes(label)) {
          activeMenusCopy.splice(activeMenusCopy.indexOf(label), 1);
        } else {
          activeMenusCopy.push(label);
        }
      } else if (activeMenusCopy.includes(label)) {
        activeMenusCopy.length = 0;
      } else {
        activeMenusCopy.length = 0;
        activeMenusCopy.push(label);
      }
      setActiveMenus(activeMenusCopy);
    },
    [activeMenus, setActiveMenus, isMobile],
  );

  const menuItems = useMemo(() => {
    const items = [];

    if (isLoggedIn) items.push({ label: 'My Account', link: `/${region}/dashboard`, className: 'menu-item__mobile' });

    if (isGalleryDashboard) {
      items.push({
        label: 'Contact',
        link: `mailto:${email}`,
      });
    } else {
      items.push({ label: 'Support', target: '_blank', link: process.env.REACT_APP_ZENDESK_CLIENT_SUPPORT_URL });
    }

    if (isLoggedIn) items.push({ label: 'Log Out', link: `/${region}/auth/logout` });

    return items;
  }, [onToggle, region, activeMenus, email]);

  return menuItems;
};

export default useMenuItems;
