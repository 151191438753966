const DEFAULT_MESSAGE = 'A validation error occurred';

class ServiceValidation extends Error {
  constructor(errors, message = DEFAULT_MESSAGE) {
    super(message);
    this.name = this.constructor.name;
    this.errors = errors;
    this.defaultMessage = DEFAULT_MESSAGE;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }

  setErrors = (formApi, notificationStack) => {
    Object.keys(this.errors).forEach(field => {
      // If the field exists
      if (typeof formApi?.fieldExists === 'function' && formApi?.fieldExists(field)) {
        formApi.setError(field, this.errors[field]);
      } else if (typeof notificationStack === 'function') {
        // If not and there is a notistack / useState attached
        notificationStack(this.errors[field], { variant: 'error' });
      } else if (typeof notificationStack?.show === 'function') {
        // If not and there is a primereact message holder
        notificationStack.show({
          severity: 'error',
          detail: this.errors[field],
          sticky: true,
        });
      } else {
        alert(this.errors[field]);
      }
    });
  };

  getErrors = () => this.errors;
}

export default ServiceValidation;
