import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import * as fromState from 'reducers';

export const withRegion = WrappedComponent => {
  const mapStateToProps = (state, ownProps) => {
    return {
      region: fromState.getActiveRegion(state),
    };
  };

  return connect(mapStateToProps, {})(WrappedComponent);
};
