import ServiceError from './ServiceError';

const DEFAULT_MESSAGE = "You don't have access to this resource";

class ServiceForbidden extends ServiceError {
  constructor(error, message = DEFAULT_MESSAGE) {
    const defaultMessage = DEFAULT_MESSAGE;
    super(error, message, defaultMessage);
  }
}

export default ServiceForbidden;
