/* global module */
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ScrollToTop } from './helpers/ScrollBehaviour';
import { loadableLayout, AuthedLayout, DefaultLoading } from './Layouts';
import theme from './styles/theme/clientTheme';
import Login from './components/Auth/Login';
import Logout from './components/Auth/Logout';
import AuthModel from './api/Auth';

import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './styles/theme/theme.scss';
import './styles/tailwind.css';
import './styles/app.scss'; // Legacy styles imported after Tailwind to take precedence...
import { setProfile } from './actions/profile';
import Service from './api/AxiosService';
import useCustomFlag from './hooks/useCustomFlag';
import Loading from './components/Shared/Loading';
import Activate from './components/Auth/Activate';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import RecoveryQuestions from './components/Account/RecoveryQuestions';

const VBAOnBoarding = loadableLayout(() => import('./pages/VBAOnBoarding'))();
const LoCOnBoarding = loadableLayout(() => import('./pages/LoCOnBoarding'))();
const GalleryDashboard = loadableLayout(() => import('./components/GalleryDashboard'))(AuthedLayout);
const TVR = loadableLayout(() => import('./components/TVR'))();
const IncreaseLoC = loadableLayout(() => import('./components/ClientDashboard/IncreaseLoC'))();

/**
 * this is a catchall (instead of a not found page - to redirect back to CodeIgniter)
 * @param {object} props route props
 * @returns {null} returns null
 */
export const NonReactRoute = ({ location }) => {
  window.location.replace(location.pathname);
  return null;
};

NonReactRoute.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

function RoutesHandler() {
  const dispatch = useDispatch();
  const {
    isAdminUser,
    isClientUser,
    isGalleryUser,
    clientDidNotCompleteCreditApplication,
    clientDidNotCompleteVerifiedBuyerApplication,
    profile = {},
  } = useSelector((state) => state.profile);
  const [loadingProfile, setLoadingProfile] = useState(true);

  const isLoggedIn = useMemo(() => Object.keys(profile).length > 0, [profile]);

  // A gallery user should not be able a client as well,
  // so a gallery user which is is a client as well will be treated as a gallery user
  const hasOnlyGalleryRole = useMemo(() => isGalleryUser && !isAdminUser, [isGalleryUser, isAdminUser]);
  const hasOnlyClientRole = useMemo(() => isClientUser && !isAdminUser && !isGalleryUser, [isClientUser, isAdminUser, isGalleryUser]);

  const isDashboardWorthyClient = useMemo(
    () => hasOnlyClientRole && !clientDidNotCompleteCreditApplication && !clientDidNotCompleteVerifiedBuyerApplication,
    [hasOnlyClientRole, clientDidNotCompleteCreditApplication, clientDidNotCompleteVerifiedBuyerApplication],
  );

  useEffect(() => {
    const removeClass = (element, className) => {
      if (element.classList) element.classList.remove(className);
      // eslint-disable-next-line no-param-reassign
      else element.className = element.className.replace(new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
    };
    removeClass(document.body, 'preload');
  }, []);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const zonAuthCheckResponse = await AuthModel.check();
        if (zonAuthCheckResponse.result !== 'success') {
          throw new Error('Failed to confirm user session');
        }

        dispatch(setProfile(zonAuthCheckResponse));
      } catch (e) {
        dispatch(setProfile({}));
        AuthModel.logout();
      } finally {
        setLoadingProfile(false);
      }
    };
    loadUser();
  }, []);

  if (loadingProfile) {
    return <Loading size={100} />;
  }

  return (
    <Route
      path="/:region"
      render={({
        location,
        match: {
          params: { region },
        },
      }) => (
        <ScrollToTop>
          <Suspense fallback={<DefaultLoading />}>
            <Switch location={location}>
              <Route exact path="/:region/tvrs/:decision/:uuid" component={TVR} />
              <Route exact path="/:region/auth/logout" component={Logout} />
              {isLoggedIn && <Redirect from="/:region/auth/login" to={`/${region}`} />}
              {isLoggedIn && <Route exact path="/:region/account/security_questions" component={RecoveryQuestions} />}
              {!isLoggedIn && <Route exact path="/" component={Login} />}
              {!isLoggedIn && <Route exact path="/:region" component={Login} />}
              {!isLoggedIn && <Redirect from="/:region/login" to={`/${region}/auth/login`} />}
              {!isLoggedIn && <Route exact path="/:region/auth/login" component={Login} />}
              {!isLoggedIn && <Route exact path="/:region/auth/activate/:token" component={Activate} />}
              {!isLoggedIn && <Route exact path="/:region/auth/forgot" component={ForgotPassword} />}
              {!isLoggedIn && <Route exact path="/:region/auth/reset/:token" component={ResetPassword} />}
              {!isLoggedIn && <Route exact path="/:region/amvb_application" component={VBAOnBoarding} />}
              {!isLoggedIn && <Route exact path="/:region/credit_application" component={LoCOnBoarding} />}
              {hasOnlyClientRole && clientDidNotCompleteCreditApplication && <Route exact component={LoCOnBoarding} />}
              {hasOnlyClientRole && clientDidNotCompleteVerifiedBuyerApplication && <Route exact component={VBAOnBoarding} />}
              {hasOnlyGalleryRole && <Route exact path="/:region/gallery-dashboard" component={GalleryDashboard} />}
              {hasOnlyGalleryRole && <Route exact path="/:region/gallery/artwork_details/:artwork_id" component={NonReactRoute} />}
              {hasOnlyGalleryRole && <Redirect from="/:region" to={`/${region}/gallery-dashboard`} />}
              {isDashboardWorthyClient && <Route exact path="/:region/dashboard" component={NonReactRoute} />}
              {isDashboardWorthyClient && <Route exact path="/:region/auth/logout" component={Logout} />}
              {isDashboardWorthyClient && <Route exact path="/:region/apply/increase_credit" component={IncreaseLoC} />}
              {isDashboardWorthyClient && <Redirect from="/:region" to={`/${region}/dashboard`} />}
              {isAdminUser && <Route exact path="/admin" component={NonReactRoute} />}
              {isAdminUser && <Redirect from="/:region" to="/admin" />}
              {!isLoggedIn && <Redirect from="/:region/account/security_questions" to={`/${region}`} />}
              {!hasOnlyGalleryRole && <Redirect from="/:region/gallery-dashboard" to={`/${region}`} />}
              {!isDashboardWorthyClient && <Redirect from="/:region/apply/increase_credit" to={`/${region}`} />}
              <Route component={NonReactRoute} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      )}
    />
  );
}

const ThemedRoutesHandler = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <RoutesHandler />
    </BrowserRouter>
  </ThemeProvider>
);

export default hot(module)(ThemedRoutesHandler);
