import React from 'react';
import FormContext from '../../helpers/FormContext';

import ForgotPasswordForm from './ForgotPasswordForm';

import './ForgotPassword.scss';
import Navigation from '../Navigation';

const ForgotPassword = () => {
  return (
    <>
      <Navigation isRouterless hideButtons />
      <section className="forgot-password">
        <h1 className="forgot-password__heading">Reset your password</h1>
        <p className="forgot-password__byline">Please enter your email address and we’ll send you a link so you can reset your password.</p>
        <FormContext.Provider value="Forgot Password">
          <ForgotPasswordForm />
        </FormContext.Provider>
      </section>
    </>
  );
};

export default ForgotPassword;
