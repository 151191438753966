const contactDetails = region => {
  let email;
  switch (region) {
    case 'us':
      email = 'gallery@artmoney.com';
      break;
    case 'nz':
    case 'au':
    default:
      email = 'gallery@artmoney.com.au';
      break;
  }

  return { email };
};

export default contactDetails;
