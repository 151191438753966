import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withRegion } from '../../helpers/withRegion';

import '../../styles/components/Shared/AccountButton.scss';

const Link = ({ className, to, hasRouter, children }) =>
  hasRouter ? (
    <NavLink target="_self" className={className} to={to}>
      {children}
    </NavLink>
  ) : (
    <a target="_self" className={className} href={to}>
      {children}
    </a>
  );

Link.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  hasRouter: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Link.defaultProps = {
  className: '',
  hasRouter: true,
};

const Logout = ({ region, hasRouter }) => (
  <Link className="account-button account-button--logout" to={`/${region}/auth/logout`} hasRouter={hasRouter}>
    Log Out
  </Link>
);

Logout.propTypes = {
  region: PropTypes.string.isRequired,
  hasRouter: PropTypes.bool,
};

Logout.defaultProps = {
  hasRouter: true,
};

const AccountButton = ({ isGalleryUser, isClientUser, isLoggedIn, region, hasRouter }) => {
  if (isGalleryUser) {
    return (
      <Link className="account-button" to={`/${region}/gallery-dashboard`} hasRouter={hasRouter}>
        Gallery&nbsp;Admin
      </Link>
    );
  }

  if (isClientUser) {
    return (
      <Link className="button__primary" to={`/${region}/dashboard`} hasRouter={hasRouter}>
        My Account
      </Link>
    );
  }

  return !isLoggedIn ? (
    <Link className="account-button" to={`/${region}/auth/login`} hasRouter={hasRouter}>
      Sign In
    </Link>
  ) : null;
};

AccountButton.propTypes = {
  isLoggedIn: PropTypes.bool,
  isClientUser: PropTypes.bool,
  isGalleryUser: PropTypes.bool,
  region: PropTypes.string.isRequired,
  hasRouter: PropTypes.bool,
};

AccountButton.defaultProps = {
  isLoggedIn: false,
  isClientUser: false,
  isGalleryUser: false,
  hasRouter: true,
};

export const LogoutButton = withRegion(Logout);

export default withRegion(AccountButton);
