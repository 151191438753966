import { useEffect, useState } from 'react';

import AuthApi from '../api/Auth';

const useUserSecretQuestions = username => {
  const [loading, setLoading] = useState(false);
  const [secretQuestions, setSecretQuestions] = useState([]);

  useEffect(() => {
    const doFetch = async () => {
      try {
        setLoading(true);
        const result = await AuthApi.getQuestions(username);

        const questions = result.data.map(({ id, question }) => ({
          label: question,
          value: id,
        }));

        setSecretQuestions(questions);
      } catch (e) {
        setSecretQuestions(false);
      }
      setLoading(false);
    };

    doFetch();

    return () => null;
  }, [username, setSecretQuestions]);

  return [loading, secretQuestions];
};

export default useUserSecretQuestions;
