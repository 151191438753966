import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';

import { withRegion } from '../../helpers/withRegion';
import AccountButton, { LogoutButton } from '../Shared/AccountButton';
import MenuItem from './MenuItem';
import useMenuItems from './useMenuItems';

import '../../styles/components/Navigation/_navigation.scss';
import '../../styles/components/CookieConsent.scss';

const isDesktop = window.matchMedia('(min-width: 64.063em)');

const Navigation = ({ isGalleryUser, isClientUser, isLoggedIn, isGalleryDashboard, region, isRouterless, hideButtons }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const menuItems = useMenuItems({ isGalleryDashboard, region, isMobile, isLoggedIn });

  const handleMobileMenuToggle = () => setMenuIsOpen((prev) => !prev);

  const handleScroll = useCallback(
    (event) => {
      if (event.target.documentElement.scrollTop > 100 && !isScrolled) {
        setIsScrolled(true);
      }
      if (event.target.documentElement.scrollTop <= 100 && isScrolled) {
        setIsScrolled(false);
      }
    },
    [isScrolled],
  );

  const handleResize = () => {
    setIsMobile(!isDesktop.matches);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    setIsMobile(!isDesktop.matches);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className={`main-nav ${isScrolled && 'main-nav--scrolled'}`}>
      <div className="main-nav__container">
        <div className="main-nav__logo">
          <img src="/assets/images/art-money-logo.png" alt="Art Money" />
        </div>
        {isMobile && (
          <a
            role="button"
            data-lenis-toggle=""
            title="Open Menu Button"
            href="#"
            className={`navbar_menu-button ${menuIsOpen && 'navbar_menu-button--open'}`}
            onClick={handleMobileMenuToggle}
          >
            <div className="menu-icon">
              <div className="menu-icon_line line-1">
                <div className="menu-icon_dot"></div>
                <div className="menu-icon_dot"></div>
                <div className="menu-icon_dot"></div>
              </div>
              <div className="menu-icon_line line-2">
                <div className="menu-icon_dot"></div>
                <div className="menu-icon_dot"></div>
                <div className="menu-icon_dot"></div>
              </div>
            </div>
          </a>
        )}

        <AnimatePresence>
          {(menuIsOpen || !isMobile) && (
            <motion.div
              className="main-nav__menu-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 1] }}
              exit={{ opacity: [1, 0] }}
              transition={{ ease: 'easeOut', duration: 0.2 }}
            >
              <motion.div
                className="main-nav__menu"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: [0, 1], x: [-100, 0] }}
                exit={{ opacity: [1, 0], x: [0, -100] }}
                transition={{ ease: 'easeOut', duration: 0.2 }}
              >
                {isMobile && (
                  <div className="main-nav__close">
                    <button type="button" onClick={handleMobileMenuToggle} aria-label="Close the Menu" />
                  </div>
                )}
                <AnimateSharedLayout>
                  <motion.ul layout className="main-nav__menu-list">
                    {menuItems.map(({ label, target, link, subMenuItems, onToggle, isOpen, className }) => (
                      <MenuItem
                        label={label}
                        target={target}
                        link={link}
                        subMenuItems={subMenuItems}
                        onToggle={onToggle}
                        isOpen={isOpen}
                        key={label}
                        hasRouter={!isRouterless}
                        className={className}
                      />
                    ))}
                  </motion.ul>
                </AnimateSharedLayout>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {!hideButtons && !isMobile && (
          <div className="main-nav__account-button">
            <AccountButton isGalleryUser={isGalleryUser} isClientUser={isClientUser} isLoggedIn={isLoggedIn} hasRouter={!isRouterless} />
          </div>
        )}
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  isLoggedIn: PropTypes.bool,
  isClientUser: PropTypes.bool,
  isGalleryUser: PropTypes.bool,
  isGalleryDashboard: PropTypes.bool,
  region: PropTypes.string.isRequired,
  isRouterless: PropTypes.bool,
  hideButtons: PropTypes.bool,
};

Navigation.defaultProps = {
  isLoggedIn: false,
  isClientUser: false,
  isGalleryUser: false,
  isGalleryDashboard: false,
  isRouterless: false,
  hideButtons: false,
};

export default withRegion(Navigation);
