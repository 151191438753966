import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const MenuItem = ({ label, target, link, subMenuItems, onToggle, isOpen, hasRouter, className }) => (
  <motion.li layout className={`menu-item ${className}`}>
    {link && !subMenuItems?.length ? (
      <a href={link} className="menu-item__parent" target={target} rel="noreferrer">
        {label}
      </a>
    ) : (
      <motion.button layout onClick={typeof onToggle === 'function' ? () => onToggle(label) : null} className={`menu-item__parent ${isOpen ? 'active' : ''}`}>
        {label}
      </motion.button>
    )}
    {subMenuItems?.length > 0 && (
      <AnimatePresence>
        {isOpen && (
          <motion.ul
            layout
            initial={{ opacity: 0, y: -24 }}
            animate={{ opacity: [0, 1], y: [-24, 0] }}
            exit={{ opacity: [1, 0], y: [0, -24] }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
          >
            {subMenuItems.map((item) => (
              <li key={item.link}>
                {hasRouter ? (
                  <NavLink to={item.link} className="menu-item__child">
                    {item.label}
                  </NavLink>
                ) : (
                  <a href={item.link} className="menu-item__child">
                    {item.label}
                  </a>
                )}
              </li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    )}
  </motion.li>
);

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  target: PropTypes.string,
  link: PropTypes.string,
  subMenuItems: PropTypes.arrayOf(PropTypes.shape({ link: PropTypes.string, label: PropTypes.string })),
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  hasRouter: PropTypes.bool,
  className: PropTypes.string,
};

MenuItem.defaultProps = {
  link: '',
  target: null,
  subMenuItems: [],
  isOpen: false,
  hasRouter: true,
  onToggle: null,
  className: '',
};

export default MenuItem;
