import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MUISelect } from '@material-ui/core';
import { useField } from 'formik';
import useGoogleAnalyticsEventTracking from '../../hooks/useGoogleAnalyticsEventTracking';

export default function Select({ label, name, options, placeholder }) {
  const [field, meta] = useField(name);
  const { sendCustomFormErrorEvent } = useGoogleAnalyticsEventTracking();
  const displayPlaceHolder = useCallback(
    selected => (selected?.length ? options.find(option => option.value === selected)?.label || '' : <em>{placeholder}</em>),
    [options],
  );

  useEffect(() => {
    if (meta.touched && meta.error) sendCustomFormErrorEvent(meta.error);
  }, [meta.touched, meta.error]);

  return (
    <FormControl fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <MUISelect
        displayEmpty
        renderValue={displayPlaceHolder}
        variant="outlined"
        value={field.value}
        onChange={field.onChange}
        value={field.value}
        name={name}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
      <FormHelperText error>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  );
}

Select.defaultProps = {
  options: [],
  placeholder: '',
  label: '',
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
};
