import React from 'react';
import { withRouter } from 'react-router';

// Extracted from https://reacttraining.com/react-router/web/guides/scroll-restoration

/**
 * Most of the time all you need is to “scroll to the top” because you have a long content page,
 * that when navigated to, stays scrolled down. This is straightforward to handle with a <ScrollToTop>
 * component that will scroll the window up on every navigation.
 */
class ScrollToTopContainer extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const ScrollToTop = withRouter(ScrollToTopContainer);

/**
 * If you have a tab interface connected to the router, then you probably don’t want to be scrolling
 * to the top when they switch tabs. Instead, how about a <ScrollToTopOnMount>
 * in the specific places you need it?
 */
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

export { ScrollToTop, ScrollToTopOnMount };
