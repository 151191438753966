import * as Yup from 'yup';

export const validatePasswordConfirmation = Yup.string().test({
  name: 'PasswordsMatch',
  message: "Confirmation password entered doesn't match the password entered",
  test: (value, { parent }) => {
    if (!parent.password) {
      return true;
    }
    return value === parent.password;
  },
});

export const validateRecoveryQuestions = Yup.array().of(
  Yup.object().shape({
    question: Yup.string().required('Please choose a question'),
    answer: Yup.string().required('The answer is required'),
    custom: Yup.string().test({
      name: 'customQuestion',
      message: 'A custom question is required.',
      test: (value, { parent }) => {
        if (parent.question !== 'custom') {
          return true;
        }
        return Boolean(value?.length);
      },
    }),
  }),
);
