import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Select from '../ClientForms/Select';

import './SecretQuestionFields.scss';
import TextInput from '../ClientForms/TextInput';

const SecretQuestionFields = ({ secretQuestions, fieldPrefix }) => {
  const [{ value }] = useField(`${fieldPrefix}.question`);
  const showCustom = useMemo(() => value === 'custom', [value]);

  return (
    <div className="secret-question-fields">
      <div className="secret-question-fields__secret-question">
        <Select options={secretQuestions} name={`${fieldPrefix}.question`} placeholder="Please select a security question..." />
      </div>
      {showCustom && (
        <div className="secret-question-fields__secret-custom">
          <TextInput name={`${fieldPrefix}.custom`} placeholder="Write your question" />
        </div>
      )}
      <div className="secret-question-fields__secret-answer">
        <TextInput name={`${fieldPrefix}.answer`} placeholder="Answer" autoComplete="off" />
      </div>
    </div>
  );
};

SecretQuestionFields.propTypes = {
  secretQuestions: PropTypes.arrayOf(PropTypes.shape({})),
  fieldPrefix: PropTypes.string,
};

SecretQuestionFields.defaultProps = {
  fieldPrefix: '',
  secretQuestions: [],
};

export default SecretQuestionFields;
