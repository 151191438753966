/* global Site */
import React, { lazy, Suspense } from 'react';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { ProgressSpinner } from 'primereact/progressspinner';

import { GoogleAnalytics } from '@customd/cd-analytics-tracker';

import { fetchActiveRegion, submitActiveRegion } from '../actions/region';
import * as fromState from '../reducers';
import Navigation from '../components/Navigation';
import Loading from '../components/Shared/Loading';

const Footer = lazy(() => import('../components/Footer'));
const Quote = lazy(() => import('../components/Shared/Quote'));
const EmailSubscribeCTA = lazy(() => import('../components/Shared/EmailSubscribeFooter'));
const RegionModal = lazy(() => import('../components/RegionModal'));
const ErrorMessage = lazy(() => import('../components/Errors/ErrorMessage'));

const isChunkError = (error) => error.message?.includes('Loading chunk ');

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
  }

  state = {
    showRegionModal: false,
    boundaryError: false,
    redirectTo: false,
    isGalleryDashboard: false,
  };

  componentDidMount() {
    this._isMounted = true;
    this.determineRegion();
    this.determineIsGalleryDashboard();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.region !== prevProps.match.params.region) {
      this.setState({
        redirectTo: false,
      });
    }
  }

  componentDidCatch(e) {
    this.setState({ boundaryError: e });
    if (isChunkError(e)) {
      setTimeout(() => {
        this.setState({ boundaryError: new Error('Timeout chunk error') });
      }, 15000);
    }
    console.error(e);
    Sentry.captureException(e);
    // eslint-disable-next-line no-console
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.testIsMobile);
    this._isMounted = false;
  }

  handleRegionSubmit = async (values) => {
    const { region } = this.props.match.params;
    try {
      await this.props.submitActiveRegion(values.country_code);
      this.setState({ showRegionModal: false });
      Site.set('check_active_region', false);

      if (region !== values.country_code) {
        this.setState({
          redirectTo: `/${values.country_code}/${this.props.match.url.slice(4)}`,
        });
      }
    } catch (Error) {
      console.error({ Error });
    }
  };

  onClearErrorState = () => {
    this.setState({ boundaryError: false, redirectTo: `/${this.props.match.params.region}` });
  };

  determineIsGalleryDashboard() {
    const { match } = this.props;
    const isGalleryDashboard = match.path.match(/gallery-dashboard/) !== null;
    this.setState({ isGalleryDashboard });
  }

  async determineRegion() {
    const { fetchActiveRegion } = this.props;
    if ('Site' in window && Site.get('check_active_region') === true) {
      const isRegionActive = await fetchActiveRegion();
      if (this._isMounted) {
        this.setState({ showRegionModal: !isRegionActive });
      }
    }
  }

  render() {
    const { component: Component, showEdmFooter = true, showEdmCarousel = true, getIsLoggedIn, isGalleryUser, isClientUser, ...rest } = this.props;

    const { showRegionModal, boundaryError, redirectTo, isGalleryDashboard } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const isAuthScreen = this.props.match.path.match(/^\/:region\/auth/);
    const isRecoveryScreen = this.props.match.path.match(/^\/:region\/account\/security_questions/);
    const isSearchScreen = this.props.match.path === '/:region/galleries';
    const isContactScreen = this.props.match.path === '/:region/contact';
    const isTVR = this.props.match.path === '/:region/tvrs/:id';

    const showEmailSubscribeCta = !isSearchScreen && !isAuthScreen && !isRecoveryScreen;

    if (boundaryError) {
      return isChunkError(boundaryError) ? (
        <Loading size={100} />
      ) : (
        <Suspense fallback={<ProgressSpinner />}>
          <ErrorMessage onRetry={this.onClearErrorState}>
            <hr /> <Quote name="Leon Battista Alberti" quote="There is no art which has not had its beginnings in things full of errors." loading={false} />
            <hr />
          </ErrorMessage>
        </Suspense>
      );
    }

    return (
      <Route
        key={this.props.match.params.region}
        {...rest}
        render={(matchProps) => (
          <div key="RouteDiv">
            <GoogleAnalytics key="GoogleAnalytics" />
            <Navigation isLoggedIn={getIsLoggedIn} isGalleryUser={isGalleryUser} isClientUser={isClientUser} isGalleryDashboard={isGalleryDashboard} />
            <main className="site-content">
              <Component {...matchProps} />
            </main>
            <Suspense
              fallback={
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ProgressSpinner />
                </div>
              }
            >
              {/* {!showEmailSubscribeCta || isSearchScreen || isGalleryDashboard || !showEdmFooter || isTVR ? null : (
                <EmailSubscribeCTA
                  isContactScreen={isContactScreen}
                  showCarousel={showEdmCarousel}
                  heading="Enjoy weekly insights and art to buy from around the world."
                />
              )} */}
              {/* <Footer /> */}
              <RegionModal showRegionModal={showRegionModal} handleRegionSubmit={this.handleRegionSubmit} />
            </Suspense>
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isGalleryUser: fromState.getIsGalleryUser(state),
    isClientUser: fromState.getIsClientUser(state),
    getIsLoggedIn: fromState.getIsLoggedIn(state),
    getIsLoginRequired: fromState.getIsLoginRequired(state),
    getSessionUserdata: fromState.getSessionUserdata(state),
    getSessionUserId: fromState.getSessionUserId(state),
  };
};

const mapDispatchToProps = {
  fetchActiveRegion,
  submitActiveRegion,
};

const ConnectedDefaultLayout = connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);

const RoutedDefaultLayout = withRouter(ConnectedDefaultLayout);

export const DefaultLoading = (props) => <RoutedDefaultLayout {...props} component={() => <div />} />;

export default ConnectedDefaultLayout;
