const DEFAULT_MESSAGE = "Server didn't respond";

class ServiceUnavailable extends Error {
  constructor(error, message = DEFAULT_MESSAGE) {
    super(message);
    this.defaultMessage = DEFAULT_MESSAGE;
  }
}

export default ServiceUnavailable;
