import Region from '../api/Region';

export const fetchActiveRegion = () => async (dispatch, getState) => {
  try {
    const response = await Region.fetchActiveRegion();

    if (response.result !== 'success') throw new Error(response.error);
    return response.is_region_active;
  } catch (e) {
    throw e;
  }
};

export const submitActiveRegion = region => async (dispatch, getState) => {
  try {
    const response = await Region.sendActiveRegion(mapRegionCodeToUrlCode(region));

    if (response.result !== 'success') throw new Error(response.error);

    dispatch(setRegionActive(region));
    return response.redirect;
  } catch (e) {
    throw e;
  }
};

export const mapRegionCodeToUrlCode = region => (region === 'gb' ? 'uk' : region);

export const setRegionActive = region => ({
  type: 'SET_ACTIVE_REGION',
  region,
});
