import React, { useState, useCallback, useRef } from 'react';
import { Messages } from 'primereact/messages';
import { useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useGoogleAnalyticsEventTracking from '../../hooks/useGoogleAnalyticsEventTracking';
import FormContext from '../../helpers/FormContext';

import SetSecretQuestionField from './SetSecretQuestionField';
import Auth from '../../api/Auth';
import useRegion from '../../hooks/useRegion';
import useHandleError from '../../hooks/useHandleError';

import './ActivateForm.scss';
import TextInput from '../ClientForms/TextInput';
import { validatePasswordConfirmation, validateRecoveryQuestions } from '../ClientForms/helpers';

const initialValues = {
  password: '',
  password_confirmation: '',
  questions: [
    {
      question: '',
      answer: '',
      custom: '',
    },
  ],
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter a password')
    .matches(/^(?=.*[a-z|A-Z])/, 'Password must contain at least one letter')
    .matches(/^(?=.*[0-9])/, 'Password must contain at least one number')
    .matches(/^(?=.{6,})/, 'Password must contain at least six characters'),
  password_confirmation: validatePasswordConfirmation,
  questions: validateRecoveryQuestions,
});

const ActivateForm = () => {
  const messagesRef = useRef();
  const [redirectTo, setRedirectTo] = useState(false);
  const [errorData, setErrorData] = useState({});

  useHandleError(errorData);

  const { sendCustomFormSubmitEvent } = useGoogleAnalyticsEventTracking();

  const region = useRegion();

  const { params } = useRouteMatch();

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      messagesRef.current.clear();

      try {
        const questions = values.questions.map(element => ({
          answer: element.answer,
          question: element.question === 'custom' ? element.custom : element.question,
        }));

        const { data } = await Auth.activate({
          ...values,
          token: params.token,
          questions,
        });

        const status = await Auth.login({
          username: data.username,
          password: values.password,
        });

        await sendCustomFormSubmitEvent({
          email: data.username,
        });

        if (status.redirect) {
          window.location = status.redirect;
          return;
        }

        setRedirectTo(`/${region}/`);
        await new Promise(resolve => setTimeout(resolve, 5000));
      } catch (error) {
        setErrorData({
          error,
          fieldExists: field => values[field],
          setError: (field, errorString) => setErrors({ [field]: errorString }),
          setAlert: messagesRef.current,
          overrideDefaultMessage: 'An error has occurred, please try again.',
        });
      }
    },
    [params, region, messagesRef, sendCustomFormSubmitEvent],
  );

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="activate-form">
          <div className="activate-form__password">
            <TextInput name="password" autoComplete="new-password" placeholder="Password" type="password" />
          </div>
          <div className="activate-form__password-conf">
            <TextInput name="password_confirmation" autoComplete="new-password" placeholder="Confirm password" type="password" />
          </div>

          <h3 className="activate-form__secret-heading">Security Question</h3>
          <p className="activate-form__secret-body">
            Now we&apos;d like you to set up a security question and answer. This will help us be sure it's you if you want to change things like your mobile
            phone number later.
          </p>
          <p className="activate-form__secret-body">
            Please set up your security question and answer below. Ensure the answer is memorable to you and not easily guessed by anyone else.
          </p>
          <SetSecretQuestionField field="questions" />

          <Messages ref={messagesRef} />

          <button type="submit" className="activate-form__submit" disabled={isSubmitting}>
            <span>Activate</span>
            <i className={`pi ${isSubmitting ? 'pi-spin pi-spinner' : 'pi-chevron-right'}`} />
          </button>
        </form>
      )}
    </Formik>
  );
};

export default ActivateForm;
