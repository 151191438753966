import { fetchWrapper } from '@customd/cd-fetch-model';

export const handleNetworkError = error => {
  throw {
    msg: error.message,
  };
};

export const handleResponseJson = response => {
  if (response.ok) {
    return response.json();
  } else {
    return response.json().then(error => {
      throw error;
    });
  }
};

export const postForm = (url, data, options) => {
  var defaults = {
    method: 'POST',
    body: new URLSearchParams(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  };

  var settings = {
    ...defaults,
    ...options,
  };

  return fetchWrapper(url, settings)
    .then(res => res, handleNetworkError)
    .then(handleResponseJson);
};
