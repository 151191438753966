import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import useSecretQuestions from '../../hooks/useSecretQuestions';
import Loading from '../Shared/Loading';
import SecretQuestionFields from './SecretQuestionFields';

import './SetSecretQuestionField.scss';
import TextInput from '../ClientForms/TextInput';

const SetSecretQuestionField = ({ field, needsPassword }) => {
  const [loading, secretQuestions] = useSecretQuestions();

  const fieldHelpers = useField(field);

  if (loading) {
    return <Loading size={42} />;
  }

  return (
    <div className="set-secret-question-field__secret_questions">
      <div className="set-secret-question-field__secret_question_row">
        {fieldHelpers[0].value.map((value, index) => (
          <SecretQuestionFields key={index} fieldPrefix={`${field}.${index}`} secretQuestions={secretQuestions} />
        ))}
      </div>
      {needsPassword && (
        <div className="secret-question-fields__secret-answer secret-question-fields__secret-question">
          <TextInput name="password" placeholder="Confirm Current password" type="password" />
        </div>
      )}
    </div>
  );
};

SetSecretQuestionField.propTypes = {
  field: PropTypes.string,
  needsPassword: PropTypes.bool,
};

SetSecretQuestionField.defaultProps = {
  field: '',
  needsPassword: false,
};

export default SetSecretQuestionField;
