import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import '../../styles/components/Shared/Quote.scss';

const Quote = ({ quote, name, loading, title }) =>
  quote ? (
    <figure className="testimonial-quote">
      <blockquote>
        {loading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          quote
        )}
      </blockquote>
      <footer>
        <cite className="testimonial_author author">{loading ? <Skeleton /> : name}</cite>
        <cite className="testimonial_company company">{loading ? <Skeleton /> : title}</cite>
      </footer>
    </figure>
  ) : null;

Quote.propTypes = {
  quote: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
};

Quote.defaultProps = {
  quote: '',
  name: '',
  loading: true,
  title: '',
};

export default Quote;
