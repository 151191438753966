const states = (state = [], action) => {
  switch (action.type) {
    case 'SET_STATES':
      return action.states;
    default:
      return state;
  }
};

export default states;
