/* global Site */
import * as Sentry from '@sentry/browser';

export default dsn => {
  try {
    Sentry.init({ dsn, environment: process.env.NODE_ENV });
    Sentry.configureScope(scope => {
      scope.setUser({ id: Site.user_id });
      scope.setExtra('Site', Site);
    });
  } catch (e) {
    // Squelch.
  }
};
