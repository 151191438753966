import React, { useState, useCallback, useRef } from 'react';
import { Messages } from 'primereact/messages';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Auth from '../../api/Auth';
import SetSecretQuestionField from '../Auth/SetSecretQuestionField';
import { withRegion } from '../../helpers/withRegion';
import useHandleError from '../../hooks/useHandleError';

import './RecoveryQuestionsForm.scss';
import { validateRecoveryQuestions } from '../ClientForms/helpers';

const initialValues = {
  password: '',
  questions: [
    {
      question: '',
      answer: '',
      custom: '',
    },
  ],
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required('The password is required.'),
  questions: validateRecoveryQuestions,
});

const RecoveryQuestionsForm = ({ region }) => {
  const [redirectTo, setRedirectTo] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(1);
  const [errorData, setErrorData] = useState({});

  const messagesRef = useRef();
  const history = useHistory();

  useHandleError(errorData);

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      messagesRef.current.clear();

      try {
        const object = { ...values };
        const questions = Object.values(values.questions);
        setNumberOfQuestions(questions.length);
        questions.forEach((obj, index) => {
          if (obj.question === 'custom') {
            object.questions[index].question = obj.custom;
          } else {
            delete object.questions[index].custom;
          }
        });
        await Auth.setQuestions(object);

        // Redirect to the base URL.
        // Other logic will take care of redirecting onwards to the correct dashboard(client or gallery)
        setRedirectTo(`/${region}`);
      } catch (e) {
        setErrorData({
          error: e,
          fieldExists: field => values[field],
          setError: (field, errorString) => setErrors({ [field]: errorString }),
          setAlert: messagesRef.current,
          overrideDefaultMessage: "Sorry, we couldn't set your security question at this time. Please try again later.",
        });
      }
    },
    [messagesRef, region],
  );

  const handleRedirect = useCallback(() => history.push(redirectTo), [history, redirectTo]);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting }) => (
        <form className="recovery-questions-form" onSubmit={handleSubmit}>
          <SetSecretQuestionField field="questions" needsPassword />

          <Messages ref={messagesRef} />

          <button type="submit" className="recovery-questions-form__submit" disabled={isSubmitting}>
            <span>Set question</span>
            <i className={`pi ${isSubmitting ? 'pi-spin pi-spinner' : 'pi-chevron-right'}`} />
          </button>
          {!!redirectTo && (
            <div className="modal">
              <div>
                <div>Your security question{numberOfQuestions > 1 ? 's have' : ' has'} been saved</div>
                <div className="modal-body">
                  {numberOfQuestions > 1 ? 'These questions and answers' : 'This question and answer'} will be used for extra confirmation if you need to reset
                  your password or change your email in the future.
                </div>
                <div className="modal-footer">
                  <div className="modal-actions">
                    <button onClick={handleRedirect} type="button">
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default withRegion(RecoveryQuestionsForm);
