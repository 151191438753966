import { combineReducers } from 'redux';

import session, * as fromSession from './session';
import region from './region';
import states from './states';
import notify from './notify';
import featured, * as fromFeatured from './featured';
import blog, * as fromBlog from './blog';
import gallery, * as fromGallery from './gallery';
import favourites, * as fromFavourites from './favourites';
import profile from './profile';

const rootReducer = combineReducers({
  session,
  region,
  notify,
  featured,
  blog,
  gallery,
  states,
  favourites,
  profile,
});

// Session information
export const getIsLoggedIn = state => fromSession.getIsLoggedIn(state.session);
export const getIsLoginRequired = state => fromSession.getIsLoginRequired(state.session);
export const getIsAccountVerified = state => fromSession.getIsAccountVerified(state.session);
export const getIsGalleryUser = state => fromSession.getIsGalleryUser(state.session);
export const getIsClientUser = state => fromSession.getIsClientUser(state.session);
export const getSessionUserId = state => fromSession.getSessionUserId(state.session);
export const getSessionUserdata = state => fromSession.getSessionUserdata(state.session);
export const getSessionRoles = (state, role) => fromSession.getSessionRoles(state.session);
export const getSessionHasRole = (state, role) => fromSession.getSessionHasRole(state.session, role);
export const getSessionHasRoles = (state, roles) => fromSession.getSessionHasRoles(state.session, roles);

// region information
export const getActiveRegion = state => state.region;
export const getNotificaion = state => state.notify;

export const getRegionalFeatured = (state, region) => fromFeatured.getRegionalFeatured(state.featured, region);
export const getStates = state => state.states;

// Blog information
export const getArticles = state => fromBlog.getArticles(state.blog);
export const getArticleIds = state => fromBlog.getArticleIds(state.blog);
export const getArticlesByCategoryId = (state, id) => fromBlog.getArticlesByCategoryId(state.blog, id);
export const getArticlesById = state => fromBlog.getArticlesById(state.blog);
export const getArticleById = (state, id) => fromBlog.getArticleById(state.blog, id);
export const getArticleLoading = state => fromBlog.getArticleLoading(state.blog);
export const getArticleError = state => fromBlog.getArticleError(state.blog);
export const getArticleCount = state => fromBlog.getArticleCount(state.blog);
export const getCategories = state => fromBlog.getCategories(state.blog);
export const getCategoryById = (state, id) => fromBlog.getCategoryById(state.blog, id);

// gallery information
export const getGalleries = state => fromGallery.getGalleries(state.gallery);
export const getGalleryIds = state => fromGallery.getGalleryIds(state.gallery);
export const getGalleriesById = state => fromGallery.getGalleriesById(state.gallery);
export const getGalleryById = (state, id) => fromGallery.getGalleryById(state.gallery, id);
export const getGalleryLoading = state => fromGallery.getGalleryLoading(state.gallery);
export const getGalleryError = state => fromGallery.getGalleryError(state.gallery);

export const getFavourites = state => fromFavourites.getFavourites(state.favourites);
export const getIsFavourite = (state, galleryId) => fromFavourites.getIsFavourite(state.favourites, galleryId);

export default rootReducer;
