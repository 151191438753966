/* global Site */
import FetchApi, { fetchGet } from '@customd/cd-fetch-model';
import AxiosService from './AxiosService';
import { postForm } from './fetchHelpers';

class AuthModel extends FetchApi {
  api_url = `${Site.base_url}am_auth/`;

  check = () => {
    return fetchGet(`${this.api_url}check`);
  };

  login = (data, config) => {
    return postForm(`${this.api_url}login`, Site.csrf_protect(data), config);
  };

  logout = () => {
    return fetchGet(`${this.api_url}logout`);
  };

  activate = (data, config) => {
    return AxiosService.post(`auth/activate`, data, config);
  };

  reset = (data, config) => {
    return AxiosService.post(`auth/reset`, data, config);
  };

  forgot = (data, config) => {
    return AxiosService.post(`auth/forgotten`, { username: data?.email || data?.username }, config);
  };

  getQuestions = (username, config) => {
    return AxiosService.post(
      `auth/reset_recovery_question`,
      {
        username,
      },
      config,
    );
  };

  setQuestions = (data, config) => {
    return AxiosService.post(`auth/user_recovery`, data, config);
  };
}

const Auth = new AuthModel();

export default Auth;
