const activeRegion = (state = false, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_REGION':
      return action.region;
    default:
      return state;
  }
};

export default activeRegion;
