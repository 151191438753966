import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import useGoogleAnalyticsEventTracking from '../../hooks/useGoogleAnalyticsEventTracking';

export default function TextInput({ label, name, placeholder, initialValue, ...rest }) {
  const [field, meta] = useField(name);
  const { sendCustomFormErrorEvent } = useGoogleAnalyticsEventTracking();

  useEffect(() => {
    if (initialValue) {
      field.onChange({ target: { name: name, value: initialValue } });
    }
  }, [initialValue]);

  useEffect(() => {
    if (meta.touched && meta.error) sendCustomFormErrorEvent(meta.error);
  }, [meta.touched, meta.error]);

  return (
    <TextField
      fullWidth
      label={label}
      placeholder={placeholder}
      variant="outlined"
      onChange={field.onChange}
      value={field.value}
      name={name}
      helperText={meta.touched && meta.error}
      error={Boolean(meta.touched && meta.error)}
      {...rest}
    />
  );
}

TextInput.defaultProps = {
  placeholder: '',
  label: '',
};

TextInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
