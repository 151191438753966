import React from 'react';
import PropTypes from 'prop-types';

import './Loading.scss';

const Loading = ({ size }) => {
  return (
    <div className="loading">
      <i className="pi  pi-spin pi-spinner" style={{ fontSize: `${size}px` }} />
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.number,
};

Loading.defaultProps = {
  size: 16,
};

export default Loading;
