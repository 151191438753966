import React from 'react';
import { Link } from 'react-router-dom';

import LoginForm from './LoginForm';

import './Login.scss';
import Navigation from '../Navigation';
import useRegion from '../../hooks/useRegion';

const Login = () => {
  const region = useRegion();

  return (
    <>
      <Navigation isRouterless hideButtons />
      <section className="login">
        <h1 className="login__heading">Sign In</h1>
        <p>Sign in to access your Art Money account</p>
        <LoginForm />

        <hr className="gutter2-bottom gutter2-top" />
        <p className="gutter-bottom">New to Art Money?</p>

        <Link className="button__secondary button__secondary--full-width" to={`/${region}/credit_application`}>
          <span>Apply for Art Money</span>
        </Link>
      </section>
    </>
  );
};

export default Login;
