import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import DefaultLayout from './DefaultLayout';
import * as fromState from '../reducers';

/**
 * This layout is set up to determine if the user is logged in or not, and if not, redirect them to the login page setting a return url
 * back to where they came from
 */
class AuthedLayout extends React.Component {
  render() {
    const { component: Component, getIsLoggedIn, ...rest } = this.props;

    // We need our region - might not be in state as yet as could have been a direct access link.
    const { region } = this.props.match.params;

    if (!getIsLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: `/${region}`,
            state: { from: this.props.location },
          }}
        />
      );
    }

    // All good return our default layout.
    return <DefaultLayout component={this.props.component} {...rest} />;
  }
}

// Mapped props to get the loggin state from our store
const mapStateToProps = (state, ownProps) => {
  return {
    getIsLoggedIn: fromState.getIsLoggedIn(state),
  };
};

export default connect(mapStateToProps)(AuthedLayout);
