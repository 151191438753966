import { useEffect, useState } from 'react';

import * as SecretQuestionsApi from '../api/SecretQuestions';

const useSecretQuestions = (includeCustom = true) => {
  const [loading, setLoading] = useState(false);
  const [secretQuestions, setSecretQuestions] = useState([]);

  useEffect(() => {
    const doFetch = async () => {
      try {
        setLoading(true);
        const result = await SecretQuestionsApi.get();

        const questions = result.data.map(({ question }) => ({
          label: question,
          value: question,
        }));

        if (includeCustom) {
          questions.push({
            label: '',
            value: '',
            disabled: true,
          });

          questions.push({
            label: 'Choose your own question...',
            value: 'custom',
          });
        }

        setSecretQuestions(questions);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    };

    doFetch();

    return () => null;
  }, [includeCustom, setSecretQuestions]);

  return [loading, secretQuestions];
};

export default useSecretQuestions;
