import { combineReducers } from 'redux';

const userdata = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
      return action.userdata;
    case 'CLEAR_SESSION_DATA':
      return {};
    default:
      return state;
  }
};

const userId = (state = null, action) => {
  switch (action.type) {
    case 'SET_SESSION_DATA':
      if (action.userdata.customer_id === undefined || action.userdata.customer_id === '') {
        return null;
      }
      return action.userdata.customer_id;
    default:
      return state;
  }
};

const isLoginRequired = (state = false, action) => {
  switch (action.type) {
    case 'SET_LOGIN_REQUIREMENT':
      return action.require_login;
    default:
      return state;
  }
};

const expiresIn = (state = false, action) => {
  switch (action.type) {
    case 'SET_SESSION_EXPIRES':
      return action.sessionExpiresIn;
    case 'SET_SESSION_EXPIRED':
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  userdata,
  userId,
  isLoginRequired,
  expiresIn,
});

export const getIsLoggedIn = state => !!state.userId;
export const getIsLoginRequired = state => !!state.isLoginRequired;
export const getIsAccountVerified = state => getIsLoggedIn(state) && state.userdata.active === '1';
export const getSessionUserId = state => state.userId;
export const getSessionUserdata = state => state.userdata;
export const getSessionRoles = state => (state.userdata && Array.isArray(state.userdata.roles) ? state.userdata.roles : []);
export const getSessionHasRole = (state, role) => getSessionRoles(state).indexOf(role) > -1;
export const getSessionHasRoles = (state, roles) => {
  const sessionRoles = getSessionRoles(state);

  return !roles.find(role => sessionRoles.indexOf(role) === -1);
};

export const getIsGalleryUser = state => (getIsLoggedIn(state) ? Boolean(parseInt(getSessionUserdata(state).gallery_id, 10)) : false);
export const getIsClientUser = state => (getIsLoggedIn(state) ? !getIsGalleryUser(state) : false);
