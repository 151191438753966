/* global Site */
import FetchApi, { fetchPost, fetchGet } from '@customd/cd-fetch-model';

class RegionModel extends FetchApi {
  api_url = `${Site.api_url}regions`;

  fetchActiveRegion() {
    return fetchGet(`${this.api_url}/is_region_active`);
  }

  sendActiveRegion(country_code) {
    return fetchPost(`${this.api_url}/set_region_active`, { country_code });
  }
}

const Region = new RegionModel();

export default Region;
