import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import Auth from '../../api/Auth';
import Loading from '../Shared/Loading';
import ErrorMessage from '../Errors/ErrorMessage';
import Quote from '../Shared/Quote';

const Logout = () => {
  const { push } = useHistory();
  const [logoutError, setLogoutError] = useState(false);

  const doLogout = useCallback(
    async times => {
      try {
        await Auth.logout();
        setLogoutError(false);
        if (window) {
          window.location = '/';
        } else {
          push('/');
        }
      } catch (e) {
        if (times < 3) {
          setTimeout(() => {
            doLogout(times + 1);
          }, 1000);
        } else {
          setLogoutError(true);
        }
      }
    },
    [push],
  );

  useEffect(() => {
    doLogout(0);
  }, [doLogout]);

  if (logoutError) {
    return (
      <ErrorMessage>
        <hr />
        <Quote name="Leon Battista Alberti" quote="There is no art which has not had its beginnings in things full of errors." loading={false} />
        <hr />
      </ErrorMessage>
    );
  }

  return <Loading size={100} />;
};

export default Logout;
