import axios from 'axios';
import { ServiceValidation, ServiceForbidden, ServiceNotFound, ServiceAbort, ServiceError, ServiceUnavailable } from '../errors';

export default function handleLaravelError(errorResponse = {}) {
  if (axios?.isCancel(errorResponse)) {
    return Promise.reject(new ServiceAbort(errorResponse));
  }
  if (!errorResponse.response) {
    // A completely empty response reveived from the server
    // would have errorResponse.response === {}, which evaluates as true.
    // So if this evaluates to false, we genuinely received no response at all.
    return Promise.reject(new ServiceUnavailable());
  }
  const { status } = errorResponse.response;
  const { errors } = errorResponse.response.data;
  const { error } = errorResponse.response.data;

  let message;

  if (errors) {
    let tempMessage = '';
    Object.keys(errors).forEach(key => {
      if (typeof errors[key] === 'string') {
        tempMessage += `${errors[key]} `;
      }
      if (Object.prototype.toString.call(errors[key]) === '[object Array]') {
        tempMessage += `${errors[key].join(', ')} `;
      }
    });
    if (tempMessage.length) {
      message = tempMessage;
    }
  }

  switch (status) {
    case 422:
      return Promise.reject(new ServiceValidation(errors, message));
    case 403:
      return Promise.reject(new ServiceForbidden(error, message));
    case 404:
      return Promise.reject(new ServiceNotFound(error));
    case 401:
      return Promise.reject(new Error(message || 'Unauthenticated'));
    default:
      return Promise.reject(new ServiceError(error, message));
  }
}
