import React from 'react';
import { Link, useParams } from 'react-router-dom';

import RecoveryQuestionsForm from './RecoveryQuestionsForm';

import './RecoveryQuestions.scss';

export default function RecoveryQuestions() {
  const { region } = useParams();
  return (
    <section className="recovery-questions">
      <h1 className="recovery-questions__heading">Update your security question</h1>
      <p className="recovery-questions__byline">
        Keeping your information secure is most important to us. As part of our security improvements we require you to create a new security question and
        answer. Ensure the answer is memorable to you and not easily guessed by anyone else.
      </p>
      <p className="recovery-questions__byline">
        If you have any questions, please{' '}
        <a href={`${process.env.REACT_APP_MARKETING_WEBSITE}/contact`} className="table__link">
          contact us
        </a>
        .
      </p>
      <RecoveryQuestionsForm />
    </section>
  );
}
