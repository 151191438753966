import React from 'react';

import FormContext from '../../helpers/FormContext';

import ActivateForm from './ActivateForm';

import './Activate.scss';

const Activate = () => {
  return (
    <section className="activate">
      <h1 className="activate__heading">Account Confirmation</h1>
      <p className="activate__byline">Please create a password (Must be a minimum of six characters and include at least one letter and one number).</p>
      <FormContext.Provider value="Account Activation">
        <ActivateForm />
      </FormContext.Provider>
    </section>
  );
};

export default Activate;
