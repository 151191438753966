import React, { useState, useCallback, useRef } from 'react';
import { Messages } from 'primereact/messages';
import * as Yup from 'yup';
import { Formik } from 'formik';

import Auth from '../../api/Auth';
import useHandleError from '../../hooks/useHandleError';
import TextInput from '../ClientForms/TextInput';

import useGoogleAnalyticsEventTracking from '../../hooks/useGoogleAnalyticsEventTracking';

import './ForgotPasswordForm.scss';

const initialValues = { email: '' };

const validationSchema = Yup.object().shape({
  email: Yup.string().email("This doesn't look like a valid email address").required('This field is required'),
});

const ForgotPasswordForm = () => {
  const messagesRef = useRef();
  const [errorData, setErrorData] = useState({});

  useHandleError(errorData);

  const { sendCustomFormSubmitEvent } = useGoogleAnalyticsEventTracking();

  const onSubmit = useCallback(
    async (values, { setErrors }) => {
      messagesRef.current?.clear();

      try {
        await Auth.forgot(values);
        messagesRef.current.show({
          detail: "Please check your email. We've sent you details on how to reset your password.",
          severity: 'success',
        });

        await sendCustomFormSubmitEvent({
          email: values.username,
        });
      } catch (error) {
        setErrorData({
          error,
          fieldExists: (field) => values[field],
          setError: (field, errorString) => setErrors({ [field]: errorString }),
          setAlert: messagesRef.current,
          overrideDefaultMessage: 'An error has occurred, please try again.',
        });
      }
    },
    [messagesRef],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="forgot-password-form">
          <div className="forgot-password-form__username">
            <TextInput name="email" placeholder="Email" />
          </div>

          <Messages ref={messagesRef} />

          <button type="submit" className={`forgot-password-form__submit ${isSubmitting && 'forgot-password-form__submit--loading'}`} disabled={isSubmitting}>
            <span>
              <span>Send Password Reset</span>
            </span>
          </button>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
