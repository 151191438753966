import React, {useState, useCallback, useRef} from 'react';

import {Messages} from 'primereact/messages';
import {Link, Redirect} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';

import Auth from '../../api/Auth';
import useRegion from '../../hooks/useRegion';

import './LoginForm.scss';
import TextInput from '../ClientForms/TextInput';
import {ServiceValidation} from '../../api/errors';
import useHandleError from '../../hooks/useHandleError';
import Service from '../../api/AxiosService';
import {setProfile} from '../../actions/profile';

const initialValues = {
  username: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  username: Yup.string()
    // .email("This doesn't look like a valid email address")
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

const LoginForm = () => {
  const messagesRef = useRef();
  const dispatch = useDispatch();
  const [redirectTo, setRedirectTo] = useState(false);
  const region = useRegion();
  const [errorData, setErrorData] = useState({});

  useHandleError(errorData);

  const onSubmit = useCallback(
    async (values, {setErrors}) => {
      messagesRef.current.clear();
      setErrorData({});
      try {
        const status = await Auth.login(values);

        if (status.result === 'validation_error') {
          throw new ServiceValidation(status.errors);
        }

        if (status.result !== 'success') throw status;

        if (status.redirect) {
          window.location = status.redirect;
          return;
        }

        const res = await Service.get('users/client_info');
        if (res.result !== 'success') {
          throw res;
        }
        delete res.result;
        dispatch(setProfile(res));

        setRedirectTo(`/${region}`);
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } catch (e) {
        if (e instanceof ServiceValidation) {
          setErrorData({
            error: e,
            fieldExists: (field) => values[field],
            setError: (field, errorString) => setErrors({[field]: errorString}),
            setAlert: messagesRef.current,
            overrideDefaultMessage: 'An error has occurred, please try again.',
          });
        } else if (Array.isArray(e.errors)) {
          e.errors.forEach((err) => {
            messagesRef.current.show({
              severity: 'error',
              detail: <span dangerouslySetInnerHTML={{__html: err}} />,
              sticky: true,
            });
          });
        } else {
          messagesRef.current.show({
            severity: 'error',
            detail: "Sorry, we couldn't log you in at this time. Please try again later.",
            sticky: true,
          });
        }
      }
    },
    [region, messagesRef, dispatch],
  );

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({handleSubmit, isSubmitting}) => (
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-form__username">
            <TextInput name="username" placeholder="Email" />
          </div>
          <div className="login-form__password">
            <TextInput name="password" placeholder="Password" type="password" />
          </div>

          <Messages ref={messagesRef} />

          <button type="submit" className={`login-form__submit ${isSubmitting && 'login-form__submit--loading'}`} disabled={isSubmitting}>
            <span>Sign In</span>
            <i className={`pi ${isSubmitting ? 'pi-spin pi-spinner' : ''}`} />
          </button>

          <Link className="login-form__forgot" to={`/${region}/auth/forgot`}>
            <strong>Forgot password?</strong>
          </Link>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
