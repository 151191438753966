import { combineReducers } from 'redux';
import { createReducers } from '@customd/cd-redux-helpers/dist/reducers';

const articleCount = (state = 0, action) => {
  if (action.type === 'SET_ARTICLE_COUNT') {
    return action.article_count;
  }

  return state;
};

export default combineReducers({
  articleCount,
  articles: createReducers('articles', ['byId', 'ids', 'loading', 'error']),
  categories: createReducers('categories', ['byId', 'ids', 'loading', 'error']),
});

export const getArticles = state => Object.values(state.articles.byId);
export const getArticleIds = state => state.articles.ids;
export const getArticlesById = state => state.articles.byId;
export const getArticleById = (state, id) => state.articles.byId[id];
export const getArticleLoading = state => state.articles.loading;
export const getArticleError = state => state.articles.error;
export const getArticleCount = state => state.articleCount;

// Note that this currently assumes all articles are in a single category, as ArtMoney doesn't acutally
// use multiple categories. If they were to add another category to the blog, we'd need to flesh out this code.
export const getArticlesByCategoryId = state => getArticles(state);

export const getCategories = state => Object.values(state.categories.byId);
export const getCategoryById = (state, id) => state.categories.byId[id];
