import ServiceError from './ServiceError';

const DEFAULT_MESSAGE = 'Resource not found';

class ServiceNotFound extends ServiceError {
  constructor(error, message = DEFAULT_MESSAGE) {
    const defaultMessage = DEFAULT_MESSAGE;
    super(error, message, defaultMessage);
  }
}

export default ServiceNotFound;
