/* global Site */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { FlagsProvider } from 'react-unleash-flags';

import Root from './Root';
import configureStore from './config/configureStore';
import configureRenders from './config/configureRenders';
import configureSentry from './config/configureSentry';
import flagConfig from './helpers/flagConfig';

const container = document.getElementById('react-app');

const storeData = Site.defaultStore;
const store = configureStore(storeData);

Site.reduxstore = store;
configureRenders(store);
configureSentry(Site.sentry_dsn);

const render = () => {
  if (!container) return <div />;
  ReactDOM.render(
    <FlagsProvider config={flagConfig}>
      <Provider store={store}>
        <Root />
      </Provider>
    </FlagsProvider>,
    container,
  );
};

store.subscribe(render);
render();
