import React from 'react';

import FormContext from '../../helpers/FormContext';
import ResetPasswordForm from './ResetPasswordForm';

import './ResetPassword.scss';

const ResetPassword = () => (
  <section className="reset-password">
    <h1 className="reset-password__heading">Set a new password</h1>
    <p className="reset-password__byline">Please set a password (Must be a minimum of six characters and include at least one letter and one number).</p>
    <FormContext.Provider value="Reset Password">
      <ResetPasswordForm />
    </FormContext.Provider>
  </section>
);

export default ResetPassword;
