import { createReducers } from '@customd/cd-redux-helpers/dist/reducers';

export default createReducers('gallery', ['byId', 'ids', 'loading', 'error']);

export const getGalleries = state => Object.values(state.byId);
export const getGalleryIds = state => state.ids;
export const getGalleriesById = state => state.byId;
export const getGalleryById = (state, id) => state.byId[id];
export const getGalleryLoading = state => state.loading;
export const getGalleryError = state => state.error;
