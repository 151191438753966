import { combineReducers } from 'redux';

export const content = (state = {}, action) => {
  switch (action.type) {
    case 'SET_FEATURED_CONTENT':
      return { ...action.posts };
    case 'CLEAR_FEATURED_CONTENT':
      return {};
    default:
      return state;
  }
};

export const loaded = (state = false, action) => {
  switch (action.type) {
    case 'SET_FEATURED_CONTENT':
    case 'SET_FEATURED_LOADED':
      return true;
    case 'CLEAR_FEATURED_LOADED':
      return false;
    default:
      return state;
  }
};

export const loading = (state = false, action) => {
  switch (action.type) {
    case 'SET_FEATURED_CONTENT':
    case 'CLEAR_FEATURED_LOADING':
      return false;
    case 'SET_FEATURED_LOADING':
      return action.loading;
    default:
      return state;
  }
};

export const error = (state = '', action) => {
  switch (action.type) {
    case 'SET_FEATURED_CONTENT':
    case 'CLEAR_FEATURED_ERROR':
      return '';
    case 'SET_FEATURED_ERROR':
      return action.error;
    default:
      return state;
  }
};

export default combineReducers({
  content,
  loaded,
  loading,
  error,
});

export const getRegionalFeatured = (state, region = 'au') => {
  let key = 'us--';
  if (region !== 'us') {
    key = 'au-nz--';
  }
  const { content } = state;

  return {
    featured_1: content && `${key}featured-article-1` in content ? content[`${key}featured-article-1`] : false,
    featured_2: content && `${key}featured-article-2` in content ? content[`${key}featured-article-2`] : false,
    featured_3: content && `${key}featured-article-3` in content ? content[`${key}featured-article-3`] : false,
  };
};
