import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    fontFamily: '"Helvetica Neue", "Open Sans", sans-serif;',
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: 'white',
        padding: 3,
        '& input': {
          border: 'none',
        },
      },
      input: {
        '& *': {
          fontStyle: 'normal',
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
      root: {
        borderRadius: '1.375em',
        padding: '12px 32px',
        '& *': {
          fontWeight: 400,
          letterSpacing: 1.1,
        },
      },
    },
    MuiPopover: {
      paper: {
        marginTop: 6,
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#DBDBDB',
          transition: '.2s ease all',
        },
        '&:hover $notchedOutline': {
          borderColor: '#000',
          transition: '.2s ease all',
        },
        '&$focused $notchedOutline': {
          borderWidth: '1px',
          borderColor: '#000',
          transition: '.2s ease all',
        },
        marginBottom: 8,
      },
      input: {
        padding: '12px !important',
        height: 'inherit !important',
      },
    },
  },
});
