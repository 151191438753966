import { useEffect } from 'react';
import { ServiceValidation } from '../api/errors';

export default function useHandleError({ error, fieldExists, setError, setAlert, overrideDefaultMessage }) {
  useEffect(() => {
    if (!error) {
      return;
    }
    if (error instanceof ServiceValidation) {
      error.setErrors({ fieldExists, setError }, setAlert);
    } else if (Array.isArray(error.errors)) {
      error.errors.forEach(detail => {
        setAlert?.show({
          severity: 'error',
          detail,
          sticky: true,
        });
      });
    } else {
      const detail = overrideDefaultMessage && error.message === error.defaultMessage ? overrideDefaultMessage : error.message;
      setAlert?.show({
        severity: 'error',
        detail,
        sticky: true,
      });
    }
  }, [error, fieldExists, setError, setAlert, overrideDefaultMessage]);

  return null;
}
