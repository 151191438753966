import { useCallback, useContext } from 'react';
import FormContext from '../helpers/FormContext';

const getSHA256Hash = async input => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hash = hashArray.map(item => item.toString(16).padStart(2, '0')).join('');
  return hash;
};

const useGoogleAnalyticsEventTracking = () => {
  // simulate google analytics in development
  if (process.env.NODE_ENV === 'development' && !window.dataLayer) {
    const dataLayer = [];
    dataLayer.push = event => {
      dataLayer[dataLayer.length] = event;
      // eslint-disable-next-line no-console
      console.log(`Simulated ${event.event} event: `, event);
    };
    window.dataLayer = dataLayer;
  }
  const formName = useContext(FormContext);

  const sendCustomFormStartEvent = useCallback(() => {
    if (window.dataLayer === undefined) return;
    window.dataLayer.push({
      event: 'custom.form.start',
      action: 'start',
      name: formName,
    });
  }, [formName]);

  const sendCustomFormStepCompleteEvent = useCallback(
    ({ stepName, stepNumber }) => {
      if (window.dataLayer === undefined) return;
      window.dataLayer.push({
        event: 'custom.form.step.complete',
        action: 'set_complete',
        name: formName,
        stepName,
        stepNumber,
      });
    },
    [formName],
  );

  const sendCustomFormErrorEvent = useCallback(
    errorText => {
      if (window.dataLayer === undefined) return;
      window.dataLayer.push({
        event: 'custom.form.error',
        action: 'form_error',
        name: formName,
        txt_value: errorText,
      });
    },
    [formName],
  );

  const sendCustomFormSubmitEvent = useCallback(
    async ({ email, country, creditLimit, artworkValue, name }) => {
      if (window.dataLayer === undefined) return;
      const hashedEmail = await getSHA256Hash(email);
      window.dataLayer.push({
        event: 'custom.form.submit',
        action: 'form_submit',
        name: name ?? formName,
        hashed_email: hashedEmail,
        ...(!!country && { country }),
        ...(!!creditLimit && { creditLimit }),
        ...(!!artworkValue && { artworkValue }),
      });
    },
    [formName],
  );

  return {
    sendCustomFormStartEvent,
    sendCustomFormStepCompleteEvent,
    sendCustomFormErrorEvent,
    sendCustomFormSubmitEvent,
  };
};

export default useGoogleAnalyticsEventTracking;
